/**
 * Created by tatarinov on 29.08.16.
 * Событиея: iasRender
 */
;(function($, undefined) {
  'use strict';

  var pluginName = 'argillaIasCreator';
  var pluginData = 'jquery_plugin_' + pluginName;
  var pluginDefaultOptions = {
    iasOptions: {},
    triggerOptions: {},
    pagingOptions: {},
    historyOptions: {},
    productDummySelector: '.product-dummy',
    loadingClass: 'list-view-loading',
    bottomLoadingClass: 'list-view-loading-bottom',
    listViewClass: 'list-view',
    iasIndex: 'ias',
    debug: false,
  };

  var Plugin = (function() {
    function Plugin(element, options) {
      this.config = $.extend(false, pluginDefaultOptions, options || {});
      this.ias = undefined;
      this.init();
    }

    return Plugin;
  }());

  $.extend(Plugin.prototype, {
    init: function() {
      this._debugMessage('init');
      this._attachEventHandlers();
    },

    enabled: function() {
      this._debugMessage('ias is enabled');
      this.ias = jQuery.ias(this.config.iasOptions);
      this.ias.extension(new IASTriggerExtension(this.config.triggerOptions));
      this.ias.extension(new IASPagingExtension(this.config.pagingOptions));
      this.ias.extension(new IASHistoryExtension(this.config.historyOptions));
      this.attachIasEventHandlers();
    },

    disabled: function() {
      if(this.isEnabled()) {
        this.ias.off('next', function() {});
        this.ias.off('prev', function() {});
        this.ias.off('loaded', function() {});
        //this.ias.restorePagination();
        this.ias.destroy();
        delete this.ias;
        this._debugMessage('ias disabled');
      }
      else {
        this._debugMessage('ias wasn\'t enabled');
      }
    },

    isEnabled: function() {
      return this.ias !== undefined;
    },

    attachIasEventHandlers: function() {
      var plugin = this;


      this._debugMessage('attach IAS events');


      this.ias.on('next', function() {
        $(this.itemsContainerSelector).parents('.' + plugin.config.listViewClass).addClass(plugin.config.bottomLoadingClass);
      });

      this.ias.on('prev', function() {
        $(this.itemsContainerSelector).parents('.' + plugin.config.listViewClass).addClass(plugin.config.loadingClass);
      });

      this.ias.on('loaded', function(data, items) {
        $(this.itemsContainerSelector).parents('.' + plugin.config.listViewClass).removeClass(plugin.config.bottomLoadingClass).removeClass(plugin.config.loadingClass);

        var paginations = $(data).find(this.paginationSelector).first().html();

        $(this.paginationSelector).each(function (e){
          $(this).html(paginations);
        });

        window.History.pushState({url: plugin.ias.nextUrl, yiiListViewUpdate: false}, document.title, plugin.ias.nextUrl);

        // если на кнопке должен  меняться  текст при подгрузке
        if($(plugin.config.triggerOptions.triggerContainer).data('dinamic-text') != undefined) {
          $(plugin.config.triggerOptions.triggerContainer).data('dinamic-text', $(data).find(plugin.config.triggerOptions.triggerContainer).data('dinamic-text'));
        }

      });

      this.ias.on('rendered', function() {
        $(plugin.config.iasOptions.item).not(plugin.config.productDummySelector).prevAll(plugin.config.productDummySelector).remove();
        $('body').trigger('iasRender');
      })
    },

    reinit: function() {
      this._debugMessage('reinit');
        this.disabled();
        this.enabled();
    },

    _attachEventHandlers: function() {
      var plugin = this;
      this._debugMessage('attach events');

      $('body').on('afterChangePageSize', function(event, oldElement, value) {
        plugin._debugMessage('event afterChangePageSize');

        plugin.reinit();
      });

      $('body').on('yiiListViewUpdated', function(event) {
        if(window.waitChangePageSize !== undefined) {
          $('body').trigger('afterChangePageSize', window.waitChangePageSize);
          delete window.waitChangePageSize;
        }

        plugin._debugMessage('yiiListViewUpdated');
        plugin.reinit();
      });
    },

    _debugMessage: function($message) {
      if(this.config.debug == true)
        console.log($message);
    },
  });

  $.fn[pluginName] = function(options) {
    var args = arguments;

    if(options === undefined || typeof options === 'object') {
      return this.each(function() {
        if(!$.data(this, pluginData)) {
          $.data(this, pluginData, new Plugin(this, $.extend(options, $(this).data())));
        }
      });
    }
    else if(typeof options === 'string') {
      var returns;

      this.each(function() {
        var instance = $.data(this, pluginData);

        if(instance instanceof Plugin && typeof instance[options] === 'function') {
          returns = instance[options].apply(instance, Array.prototype.slice.call(args, 1));
        }
      });

      return returns !== undefined ? returns : this;
    }
  };
}(jQuery));